import React from "react";
import { Card, Button } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";

const PricingCards = ({ data }) => {
  return (
    <>
      <Card className={`text-center pricing-card my-5 my-md-0 border-0 pt-0 ${data.class}`}>
        <Card.Body className="pt-0 bg-grey">
          <Card.Title className="pricing-title">
            <h4 className="text-dark fw-bold py-1 ">{data.tier}</h4>
          </Card.Title>
          <p className="font11 my-0">{data.tag}</p>
          <div className="wow fadeInUp mt-10">
            <span className="mb-2 dollar">$</span>{" "}
            <span className="price">{data.price}</span> /month
          </div>
          <span className="mb-4 text-muted font-13 mt-2">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
          </span>
          <ul className="list-unstyled my-5">
            {data.features.map((feature, index) => (
             <span className="d-flex">
              <CheckCircleFill className=" me-2 tick_marks" /> <li key={index}>{feature}</li></span>
            ))}
          </ul>
          <Button className="get-started-btn text-white border-0">
            Subscribe
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default PricingCards;
