// src/config.js
const config = {
    dev: {
        baseUrl: "https://devapp.serveus.ai",
    },
    qa: {
        baseUrl: "https://stageapp.serveus.ai",
    },
    prod: {
        baseUrl: "https://app.serveus.ai",
    },
};

let env;
const hostname = window.location.hostname;

if (hostname.includes("dev")) {
    env = "dev";
} else if (hostname.includes("stage")) {
    env = "qa";
} else if (hostname === 'serveus.ai') {
    env = "prod";
} else {
    env = "dev"; // Default to dev if no match is found
}

const { baseUrl } = config[env] || {};

export { baseUrl };