import React from "react";
import Slider from "react-slick";
import PricingCards from "../Carousal/PricingCards";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import subscriptionData from "../../utils/subscriptionData";

const SubscriptionCarousal = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Show 2 items by default
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Show 2 items on medium screens
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1, // Show 1 item on small screens
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // Show 1 item on very small screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-100 px-md-3 px-2">
      <div className="mb-2 text-dark fw-bold text-primary-local text-center">Enhance Your Service Business Effeciency With <span className="secondaryColor">SERVEUS</span></div>
      <Slider {...settings}>
        {subscriptionData?.map((data, index) => (
          <div key={index} className="">
            <PricingCards data={data} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SubscriptionCarousal;
