import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SubscriptionCarousal from "../Sections/SubscriptionCarousal";
import { baseUrl } from "../../config";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebaropen, toggleSidebar] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const renderTooltip = (props) => (
    <StyledTooltip {...props} className="d-md-flex d-none">
      <TooltipContent>
        <SubscriptionCarousal />
      </TooltipContent>
    </StyledTooltip>
  );

  useEffect(() => {
    // Close tooltip on small screens
    if (window.innerWidth < 768 && showTooltip) {
      setShowTooltip(false);
    }
  }, [showTooltip]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is inside tooltip content
      const tooltipContent = document.querySelector(".tooltip-inner");
      const isClickInTooltip = tooltipContent?.contains(event.target);

      // Check if click is on membership button
      const isMembershipClick = tooltipRef.current?.contains(event.target);

      // Only close if click is outside tooltip content AND not on membership button
      if (!isClickInTooltip && !isMembershipClick) {
        setShowTooltip(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Sidebar sidebaropen={sidebaropen} toggleSidebar={toggleSidebar} />
      {sidebaropen ? <Backdrop toggleSidebar={toggleSidebar} /> : null}
      <Wrapper
        y={y}
        className={`flexCenter animate ${
          isHomePage ? "bg-transparent" : "white_smoke"
        }`}
        style={{ height: "80px" }}
      >
        <NavInner className="container flexSpaceCenter ">
          <span className="mb-0 serveus_logo_navbar">
            <Link className="pointer flexNullCenter" to="/">
              <LogoIcon variant="black" />
            </Link>
          </span>

          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebaropen)}
          >
            <BurgerIcon />
          </BurderWrapper>

          <UlWrapperRight className="flexNullCenter mb-0 gap-4 ">
            {/* <li className="semiBold font15 pointer flexCenter">
              <Link to="/connect-and-collaborate ">
                <span
                  className={`cl-white ${
                    location.pathname === "/connect-and-collaborate" &&
                    !showTooltip
                      ? "active"
                      : ""
                  }`}
                >
                  Marketplace
                </span>
              </Link>
            </li> */}

            <li className="semiBold font15 pointer flexCenter">
              <Link to="/marketplace">
                <span
                  className={`cl-white ${
                    location.pathname === "/marketplace" && !showTooltip
                      ? "active"
                      : ""
                  }`}
                >
                  Marketplace
                </span>
              </Link>
            </li>

            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip}
              trigger={["click"]}
              show={showTooltip}
              onToggle={(nextShow) => {
                if (window.innerWidth >= 768) {
                  setShowTooltip(nextShow);
                } else {
                  setShowTooltip(false);
                }
              }}
            >
              <li className="semiBold font15 pointer  " ref={tooltipRef}>
                <span className={showTooltip ? "active" : ""}>Membership</span>
              </li>
            </OverlayTrigger>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                className="text-white"
                to="/about"
                spy={true}
                smooth={true}
                offset={-60}
              >
                <span
                  className={` cl-white ${
                    location.pathname === "/about" ? "active" : ""
                  } `}
                >
                  About
                </span>
              </Link>
            </li>
            {/* <li className="semiBold font15 pointer flexCenter">
              <Link to="/work-buddy">
                <span
                  className={`cl-white ${
                    location.pathname === "/work-buddy" && !showTooltip
                      ? "active"
                      : ""
                  }`}
                >
                  Work Buddy
                </span>
              </Link>
            </li> */}

            <li className="semiBold font15 pointer">
              <Link to="rent-a-robot" spy={true} smooth={true} offset={-80}>
                <span
                  className={`cl-white ${
                    location.pathname === "/rent-a-robot" && !showTooltip
                      ? "active"
                      : ""
                  }`}
                >
                  Rent A Robot
                </span>
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                className="text-white"
                to="/owner-operators"
                spy={true}
                smooth={true}
                offset={-60}
              >
                <span
                  className={` cl-white ${
                    location.pathname === "/owner-operators" ? "active" : ""
                  } `}
                >
                  Owner Operators
                </span>
              </Link>
            </li>

            <li className="semiBold font15 pointer cursor-pointer">
              <span>
                {" "}
                <Link to={baseUrl}>

                  <span className="text-center cl-white">Log in</span>
                </Link>
              </span>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <span>
                <Link to={`${baseUrl}/auth/choose-profile`}>
                  <span className=" cl-white">Sign up</span>
                </Link>
              </span>
            </li>

            {/* <li className="semiBold font15 pointer ">
              <Link to="/">
               <span className="flexCenter gap-2"><i className="bi bi-telephone cl-white"></i> <span className="cl-white">Serveus 911</span> </span>  
              </Link>
            </li> */}
          </UlWrapperRight>

          <Btn911 className="flexNullCenter mb-0 gap-4">
            <Btn911Li className="semiBold font15 pointer flexCenter gap-2 rounded-radius waitlist-btn ">
              <Link to="/serveus911">
                <span
                  className={`cl-white ${
                    location.pathname === "/serveus911" && !showTooltip
                      ? "active"
                      : ""
                  }`}
                >
                  <i className="bi bi-telephone cl-white"></i>{" "}
                  <span className="cl-white">Serveus 911</span>{" "}
                </span>
              </Link>
            </Btn911Li>
            {/* <li className="semiBold font15 pointer cursor-pointer">
             <span> <Link to="/">
                <span className="text-center text-dark">Log in</span>
              </Link></span>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <span><Link to="/">
                <span className="text-dark">Sign up</span>
              </Link></span>
            </li> */}
          </Btn911>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  backdrop-filter: blur(40px);
  box-shadow: ${(props) =>
    props.y > 0 ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none"};
  -webkit-backdrop-filter: blur(40); /* For Safari */
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
`;

const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 1400px) {
    display: block;
  }
`;

const UlWrapperRight = styled.ul`
  color: white;
  background-color: var(--primary-color);
  border-radius: 50px;
  padding: 12px 60px;
  @media (max-width: 1080px) {
    padding: 12px 15px !important;
  }
  // @media (max-width: 760px) {
  //   display: none;
  // }

  @media (max-width: 1400px) {
    display: none;
  }
`;

const Btn911 = styled.ul`
  margin-bootom: 0 !important;
  border-radius: 50px;
  padding: 0;
  @media (max-width: 1400px) {
    display: none;
  }
`;
const Btn911Li = styled.li`
  padding: 12px 30px;
}
`;
const StyledTooltip = styled(Tooltip)`
  background: white !important;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0;
  z-index: 1050;
`;

const TooltipContent = styled.div`
  width: 660px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 10px;
`;
