const subscriptionData = [
    {
        price: 0,
        tier: "FREE",
        features: ['Communication Network', 'All Marketplace Category',
            'Google Business Profile',
            'Virtual Billboard',
            'CRM, Billing & Invoicing',
            'FedNow Services', 'Credit/Debit Card Options',]
        ,
        class: 'free_trial',
        tag: 'Free access for 14 days',
    },
    {
        price: 49.99,
        tier: "BASIC",
        features: ['Communication Network', 'One Marketplace Category', 'Google Business Profile',
            'Virtual Billboard',
            'CRM, Billing & Invoicing',
            'FedNow Services', 'Credit/Debit Card Options',],
        class: 'monthly',
        tag: 'Try Basic',
    },
    {
        price: 69.99,
        tier: "GOLD",
        features: ['Communication Network', 'Two Marketplace Cateory', 'Google Business Profile',
            'Virtual Billboard',
            'CRM, Billing & Invoicing',
            'FedNow Services', 'Credit/Debit Card Options',],
        class: 'enterprise',
        tag: 'Get Golden Access',
    },
    {
        price: 99.99,
        tier: "PLATINUM",
        features: ['Communication Network', 'Three Marketplace Cateory', 'Google Business Profile',
            'Virtual Billboard',
            'CRM, Billing & Invoicing',
            'FedNow Services', 'Credit/Debit Card Options',],
        class: 'golden',
        tag: 'Get Platinum Features',
    },
];
export default subscriptionData