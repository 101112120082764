import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// Assets
import LogoImg from "../../assets/svg/Logo";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg position-fixed bottom-0 vw-100">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter footer_padding d-flex flex-lg-row flex-column"
            // style={{ padding: "20px 0" }}
          >
            <StyleP className="mb-0">
              <span>
              <Link
                className=" animate pointer d-flex justify-content-center justify-content-lg-start"
                to="/"
                // smooth={true}
                offset={-80}
              >
                <LogoImg variant="white" />
              </Link>
              </span>
            </StyleP>
           
            {/* <StyleP className="whiteColor font16 pointer fw-medium mb-0">
             Find a Service
            </StyleP> */}
           <div className="d-flex gap-4 justify-content-evenly ">
            <StyleP className="whiteColor font16 pointer fw-medium mb-0">
              <Link
                to="/privacy-policy"
                className="text-decoration-none text-white"
              >
                Privacy Policy
              </Link>
            </StyleP>
            <StyleP className="whiteColor font16 pointer fw-medium mb-0">
              <Link
                to="/support"
                className="text-decoration-none text-white"
              >
                  Support
              </Link>
            </StyleP>
            <StyleP className="whiteColor font16 pointer fw-medium mb-0">
              <Link
                to="/terms-of-use"
                className="text-decoration-none text-white"
              >
                Terms of use
              </Link>
            </StyleP>

            </div>
            <StyleP className="whiteColor font16 fw-medium mb-0">
              © {getCurrentYear()} - <span className=" font16">Serveus</span>{" "}
              All Right Reserved
            </StyleP>

            {/* <Link className="whiteColor animate pointer font16" to="home" smooth={true} offset={-80}>
              Back to top
            </Link> */}
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 10px 0;
  }
  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;
