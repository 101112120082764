import React, { useState } from "react";
import styled from "styled-components";
import IconLogo from "../../assets/img/Icon-Logo.png";
import AppleStore from "../../assets/svg/AppleStore.svg";
import GoogleStore from "../../assets/svg/GoogleStore.svg";
import Serveus from "../../assets/img/serveuslogoOrange.png";

export default function DownloadApp() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
      setIsOpen(!isOpen);
  };
  const handleMouseLeave = () => setIsOpen(false);

  return (
    <>
      <Wrapper>
        <Image src={IconLogo} alt="Icon" onMouseEnter={toggleModal} className={`${isOpen ? "d-none" : "d-block"} `}  />
        <Box
          className={`${
            isOpen ? "open" : ""
          } d-flex  flex-column justify-content-between p-3 align-items-center`}
        
            onMouseLeave={handleMouseLeave}>
          <Img src={Serveus} />
          <Content className="d-flex  flex-column justify-content-between primaryColor
           gap-2">
            <h5 className=" text-center primaryColor fw-bold ">
              Download The App
            </h5>
            <Button className="d-flex   justify-content-evenly  gap-1">
              <Button1 src={AppleStore}></Button1>
              <Button2 src={GoogleStore}></Button2>
            </Button>
            <p className=" text-center primaryColor fw-bold my-2">
              or use it on the website
            </p>
          </Content>
        </Box>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`

`;

const Image = styled.img`
border: 1px solid var(--grey-color);
border-left: none;
  border-radius: 0% 50% 50% 0%;
  width: 4%;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 999;
  cursor: pointer;

  /* Animation to slide in and fade in */
  animation: fadeInSlide 0.7s ease-out forwards;

  @keyframes fadeInSlide {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
    @media (max-width: 768px) {
    width: 10%;
  
 
`;

const Box = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  width: 370px;
  height: 230px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 10px 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateX(-100%);
  transition: transform 0.8s ease-in-out;
  z-index: 1000;

  &.open {
    transform: translateX(0);
  }
   
`;

const Img = styled.img`
  width: 40%;
`;
const Content = styled.div`
p{
  color: var(--primary-color)}`;
const Button = styled.div``;
const Button1 = styled.img``;
const Button2 = styled.img``;